.resume-container{
    margin: 0;
    background-image: url(../media/MountainTop.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 50vh;
    filter: brightness(80%);
}

.resume-text{
    margin: 10rem 40rem 0rem 40rem;
    color: white;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-shadow: darkblue 0.07rem 0.07rem;
}

.resume{
    margin: 1rem 48rem 7rem 48rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 0.5rem;
}
.resume-link{
    background-color: white;
    padding: 1rem;
    color: black;
    border-radius: 0.5rem;
}
.resume-link:hover{
    color: black;
    background-color:#89bac9;
    transition: all ease-in-out 150ms;
}

@media screen and (max-width:1700px) {
    .resume-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }
    .resume-text{
        margin: 0;
    }
    .resume{
        margin: auto
    }
}

@media screen and (max-width:800px) {
    .resume-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }
    .resume-text{
        margin: 0;
    }
    .resume{
        margin: 0.5rem 10rem 0rem 10rem;
    }
}