.contact-column{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0rem 25rem 1rem 25rem;
}
.contact{
    background-color: #f4f4f4;
    margin: 0rem 0rem 3rem 0rem;
}

.input-box{
    padding:0.5rem  
}

.umessage{
    height: 10rem;
    margin: 0rem 0rem 1.5rem 0rem;
}
#contact-button{
    color: white;
    background-color: black;
}
#contact-button:hover{
    background-color: #89bac9
}

@media screen and (max-width:900px) {
    #contact{
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .contact-column{
        margin: 0;
    }
}