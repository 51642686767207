.outer-container{
    /* border: 0.5rem solid black; */
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: #f4f4f4
}

.component-container{
    scroll-margin-top: 5rem;
    height: 25rem;
}

.inner-container{
    /* border: 0.2rem solid red; */
    margin: 0rem;
    padding: 1rem;
    max-width: 100%;
}

.navlink{
    margin:0.7rem;
    text-decoration: none;
}

.button{
    max-width: 12rem;
    max-height: 4rem;
    margin: 0.5rem;
}
#hero-image {
    background-image: url(../media/mainpage-pano.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    max-width: 100%;
    filter: brightness(80%);
  }

  .hero-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22rem;
    color: azure;
  }

  #experience{
    height: 60rem;
  }

  #portfolio{
    height: 50rem;
  }
