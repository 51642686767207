#portfolio-header{
    display: flex;
    justify-content: center;
    margin: 5rem 10rem 5rem 10rem;
    font-size: 28px;
}
/* #portfolio-buttons{
    display: flex;
    justify-content: space-evenly;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 5%;
} */
/* .portfolio-button{
    width: 25%;
} */

#guess-the-number{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Guess\ the\ Number\ .jpg);

}
#zorkington{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Zorkington.jpg);

}
#jeopardy{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Jeopardy\ .jpg);

}
#react-portfolio{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Portfolio.jpg);
}
#yelpington{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Yelpington.jpg);
}
#flip-profile{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../media/Flip_Profile.jpg);
}

#portfolio{
    display: flex;
    justify-content: center;
    height: 100%;
}

.port-dis-row{
    margin: 0rem 0rem 0rem 0rem;
}
#port-dis-bottom-row{
    margin: 0rem 0rem 7rem 0rem;
}

.port-dis-col{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    margin: 0rem;
    height: auto;
}
.port-dis-box{
    height: 20rem;
    width: 20rem;
    margin: 0.1rem;
    border: solid black 0.2rem;
}

.port-details{
    opacity: 0;
    color: white;
    background-color: #3d96c2be;
    padding: 2rem;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 500ms;
}
.port-details:hover{
    opacity: 1;
}
.port-header{
    font-size: larger;
    margin: 0rem 0rem 0.5rem 0rem;
}
.port-description{
    margin: 0rem 0rem 0.5rem 0rem;
}

a{
    margin: 0;
    display: inline-block;
    height: 100%;
    padding: 0;
    color: white;
    text-decoration: none;
}

a:hover{
    color: white;
    text-decoration: none;
}

@media screen and (max-width:800px) {
    #portfolio-buttons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    #portfolio-header{
        text-align: center;
    }
    .port-dis-col{
        display: flex;
        flex-direction: column;
    }
    
}