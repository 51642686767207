.experience-column{
    border: 0.3rem solid #e2ddddc3;
    background-color: white;
    height: 20rem;
    overflow: scroll;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
}
.left-column{
    margin-left: 20rem;
    margin-right: 2rem;
    padding: 1rem;
}
.right-column{
    margin-right: 20rem;
    margin-left: 2rem;
    padding: 1rem;
}
.exp-row{
    padding: 1rem;
}
#row-top{
    height: 20rem;
}
#row-bottom{
    height: 20rem;
}
#experience-header{
    text-align: center;
    padding: 4rem;
}
ol > li {
    list-style: circle;
}
@media screen and (max-width:900px) {
    .experience-column{
        display: flex;
        flex-direction: column;
        margin: 0;
        height: 20rem;
    }
    .left-column{
        margin: 0;
    }
    .right-column{
        margin: 0;
    }
    .exp-row{
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    #experience{
        height: 100%;
    }

}