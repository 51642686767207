.navbar-outer-container{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.navbar-upper-right-column{
    display: flex;
    flex-direction: row-reverse;
}

.transform-active{
    background-color: #3d96c2;
    transition: all ease-in-out 500ms;
    height: 5rem;
    
}
.transform-clear{
    transition: all ease-in-out 500ms;
    height: 5rem;
}

.ant-anchor-link-title{
    color: azure;
    font-size: 0.9rem
}

.navbar-navlink{
    text-decoration: none;
}

#navbar-list{
    display: flex;
    /* flex-direction: row-reverse; */
    padding: 0.5rem;
    text-decoration: none;
    list-style: none;
}

a{
    padding: 0.5em;
    font-family: 'IBM Plex Sans', sans-serif;
}

li a{
    text-decoration: none;
    color: white
}

li a:hover{
    color: black;
    transition: all ease-in-out 300ms;
    /* background-color: #C23D96; */
    border-radius: 0.5em;
}

@media screen and (max-width:500px) {
    .navbar-upper-right-column{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }
    a{
        padding: 0;
    }
}