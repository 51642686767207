.skills-column{
    display: flex;
    justify-content: space-around;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 3%;
}
.skills{
    background-color: #f4f4f4;
    height: 22rem;
}

#skills-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.skills-tag{
    display: flex;
    height: 3rem;
    width: 3rem;
}

.skills-tag:hover{
    cursor: pointer;
    color: #3d96c2;
    transition: all ease-in-out 250ms;
}

.skills-description{
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
}

@media screen and (max-width:800px) {
    .skills-description{
        margin-top: 5%;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }
    .skills-column{
        margin: 5% 0 0 0;
    }

}