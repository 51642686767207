.about-columns{
    display: flex;
}
.about-paragraph{
    margin: 0rem 1rem 1rem 1rem;
    padding: 2rem;
    border: 0.3rem solid #e2ddddc3;
    height: 18rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    background-color: #3d96c2;
    color: #f4f4f4;;
}
.about-paragraph-left{
    margin: 0rem 1rem 1rem 15rem;
}
.about-paragraph-right{
    margin: 0rem 15rem 1rem 1rem
}
.about{
    background-color: #f4f4f4;
    height: 30rem;
}

#about-me{
    margin: 1rem 0rem 1rem 0rem;
}

span a{
    margin: 0;
    padding: 0;
    color: white;
    text-decoration: underline
}
@media screen and (max-width:900px) {
    #about{
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .about-paragraph-left{
        margin: 0;
        overflow: auto;
    }
    .about-paragraph-right{
        margin: 0;
        overflow: auto;
    }
    .about-paragraph{
        margin: 0;
        overflow: auto;
        height: auto;
    }
    .about-columns{
        flex-direction: column;
        padding: 1rem;
    }
}

