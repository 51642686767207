.footer-upper-column{
    display: flex;
    justify-content: center;
    padding: 0.5rem
}
.footer-lower-column{
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    padding-top: 1rem;
}

.footer-outer-container{
    height: 20rem;
    background-color: #3d96c2;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}

.footer-navlink{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'IBM Plex Sans', sans-serif;
}
.icon{
    color: black;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0rem 1.5rem 0rem 2rem
}
#inner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon:hover{
    color: white;
    transition: all ease-in-out 250ms;
}

#pagetoplink{
    text-decoration: none;
    color: white;
}
#pagetoplink:hover{
    color: black;
    transition: all ease-in-out 250ms;
}